import { useEffect, useState } from "react";

export default function LooperOptions({ children, times }) {
    const [keys, setKeys] = useState([])

    useEffect(() => {
        let keysTemp = []

        for (let index = 0; index < times; index++) {
            if (index < 6) {
                keysTemp.push(index)
            }
        }

        setKeys(keysTemp)
    }, [times])

    return (
        <>
            {keys.map((item, index) => {
                return (
                    <option value={index}>{index}</option>
                )
            }
            )}
        </>
    );
}