const FuncUtil = {
    calculatePMT: (rate, nper, pv, fv, type) => {
        /*
         * rate   - interest rate per month
         * nper   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        let pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (rate === 0)
            return -(pv + fv) / nper;

        pvif = Math.pow(1 + rate, nper);
        pmt = - rate * (pv * pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + rate);
        return pmt;
    },
    calculateRate: (nper, pmt, pv, fv, type, guess) => {
        console.log(nper, pmt, pv, fv, type, guess)

        // Sets default values for missing parameters
        fv = typeof fv !== 'undefined' ? fv : 0;
        type = typeof type !== 'undefined' ? type : 0;
        guess = typeof guess !== 'undefined' ? guess : 0.1;

        // Sets the limits for possible guesses to any
        // number between 0% and 100%
        var lowLimit = 0;
        var highLimit = 1;

        // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
        // the solution as valid.
        var tolerance = Math.abs(0.00000005 * pmt);

        // Tries at most 40 times to find a solution within the tolerance.
        for (var i = 0; i < 40; i++) {
            // Resets the balance to the original pv.
            var balance = pv;

            // Calculates the balance at the end of the loan, based
            // on loan conditions.
            for (var j = 0; j < nper; j++) {
                if (type == 0) {
                    // Interests applied before payment
                    balance = balance * (1 + guess) + pmt;
                } else {
                    // Payments applied before insterests
                    balance = (balance + pmt) * (1 + guess);
                }
            }

            // Returns the guess if balance is within tolerance.  If not, adjusts
            // the limits and starts with a new guess.
            if (Math.abs(balance + fv) < tolerance) {
                return guess;
            } else if (balance + fv > 0) {
                // Sets a new highLimit knowing that
                // the current guess was too big.
                highLimit = guess;
            } else {
                // Sets a new lowLimit knowing that
                // the current guess was too small.
                lowLimit = guess;
            }

            // Calculates the new guess.
            guess = (highLimit + lowLimit) / 2;
        }
    },
    calculateAnnualInterestRate(paymentPeriods, paymentAmount, presentValue, futureValue, paymentType) {
        const tolerance = 0.0000001; // Tolerance for the iteration
        const maxIterations = 10000; // Maximum number of iterations

        let rate = 0.1;

        for (let i = 0; i < maxIterations; i++) {
            let denominator = presentValue + paymentAmount * ((1 - Math.pow(1 + rate, paymentPeriods)) / rate) * (1 + rate * paymentType);
            let numerator = futureValue + paymentAmount * ((1 - Math.pow(1 + rate, paymentPeriods)) / rate);

            let newRate = rate - Math.log((denominator - numerator) / (paymentAmount * paymentPeriods)) / paymentPeriods;

            if (Math.abs(newRate - rate) < tolerance) {
                rate = newRate;
                break;
            }

            rate = newRate;
        }

        return rate * 12 * 100;
    },
    calculateCurrentAge: (birthYear, birthMonth, birthDay) => {
        var birthDate = new Date(birthYear, birthMonth - 1, birthDay); // Month is 0-based
        var today = new Date();

        var ageInMilliseconds = today - birthDate;
        var ageDate = new Date(ageInMilliseconds); // Convert milliseconds to a date

        var years = ageDate.getUTCFullYear() - 1970; // Start from 1970 since it's the epoch year
        var months = ageDate.getUTCMonth();
        var days = ageDate.getUTCDate() - 1; // Subtract 1 day to handle UTC date behavior

        return {
            years: years,
            months: months,
            days: days + 1
        };
    },
    calculateAgeBetweenTwoDate: ({ birthYear, birthMonth, birthDay }, { parramYear, parramMonth, parramDay }) => {
        var birthDate = new Date(birthYear, birthMonth - 1, birthDay); // Month is 0-based
        var parramDate = new Date(parramYear, parramMonth - 1, parramDay);

        var ageInMilliseconds = parramDate - birthDate;
        var ageDate = new Date(ageInMilliseconds); // Convert milliseconds to a date

        var years = ageDate.getUTCFullYear() - 1970; // Start from 1970 since it's the epoch year
        var months = ageDate.getUTCMonth();
        var days = ageDate.getUTCDate() - 1; // Subtract 1 day to handle UTC date behavior

        return {
            years: years,
            months: months,
            days: days
        };
    },
    calculatePresentValue: (rate, periods, payment, future, type) => {
        // Initialize type
        var type = (typeof type === 'undefined') ? 0 : type;

        // Evaluate rate and periods (TODO: replace with secure expression evaluator)
        rate = eval(rate);
        periods = eval(periods);

        // Return present value
        if (rate === 0) {
            return - payment * periods - future;
        } else {
            return (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods);
        }
    },
    customFloor: (value, nearest = 1.0) => {
        let wholeNumber = Math.floor(value)
        let fraction = value - wholeNumber
        let factor = Math.floor(fraction / nearest)
        return wholeNumber + (nearest * factor)
    },
    formatRupiah: (value) => {
        if (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        } else {
            return ''
        }
    },
    isObjectEmpty: (obj) => {
        return Object.keys(obj).length == 0
    }
}

export default FuncUtil