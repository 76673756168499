const DateUtil = {
    getNowDateFormatted: () => {
        try {
            const now = new Date();

            const years = now.getFullYear();
            const months = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
            const date = now.getDate() > 9 ? now.getDate() : `0${now.getDate()}`;

            return `${years}/${months}/${date}`
        } catch (error) {
            return '-'
        }
    },
    formatDateObjToHumanReadable: (dateObj) => {
        if (typeof dateObj == 'object') {
            const monthsName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

            const years = dateObj.getFullYear();
            const months = monthsName[dateObj.getMonth()];
            const date = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`;

            return `${date} ${months} ${years}`
        }

        return ''
    },
    getDateObjAddMonthFromNow: (monthAdd) => {
        const dateObj = new Date()

        return new Date(dateObj.setMonth(dateObj.getMonth() + monthAdd))
    },
    formatDateObjToDMY: (dateObj) => {
        const years = dateObj.getFullYear();
        const months = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`;
        const date = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`;

        return `${date}/${months}/${years}`
    }
}

export default DateUtil
