import { useEffect, useState } from "react";
import "./App.css";
import "7.css/dist/7.css";
import "./index.css";
import kprkPosJson from "./data/kprk_pos.json";
import statusPembiayaanJson from "./data/status_pembiayaan.json";
import jenisPensiunJson from "./data/jenis_pensiun.json";
import jenisProdukJson from "./data/jenis_produk.json";
import LooperOptions from "./utils/LooperOptions";
import FuncUtil from "./utils/FuncUtil";
import * as formulajs from "@formulajs/formulajs";
import DateUtil from "./utils/DateUtil";
import APP_CONFIG from "./config/APP_CONFIG";
import axios from "axios";

function App() {
  const [arrJenisProduk, setArrJenisarrJenisProduk] = useState([]);
  const [selectedJenisProduk, setSelectedJenisProduk] = useState("");

  const [arrBpr, setArrBpr] = useState([]);
  const [selectedBPR, setSelectedBPR] = useState({});

  const [arrFrontingAgent, setArrFrontingAgent] = useState([]);
  const [selectedFrontingAgent, setSelectedFrontingAgent] = useState({});

  const [tabelAsuransiTarifAsuransi, setTabelAsuransiTarifAsuransi] = useState(0);

  const [tabelAsuransi, setTabelAsuransi] = useState({});

  const [kprkPosArr, setKprkPosArr] = useState([]);
  const [selectedKprkPosArrArr, setSelectedKprkPosArr] = useState({});
  const [statusPembiayaanArr, setStatusPembiayaanArr] = useState([]);
  const [selectedStatusPembiayaan, setSelectedStatusPembiayaan] = useState("");
  const [jenisPensiunArr, setJenisPensiunArr] = useState([]);
  const [selectedJenisPensiun, setSelectedJenisPensiun] = useState("");
  const [namaDebitur, setNamaDebitur] = useState("");
  const [nopen, setNopen] = useState("");
  const [pelunasanKe, setPelunasanKe] = useState(null);
  const [asalKantorBayar, setAsalKantorBayar] = useState("POS");
  const [noKtp, setNoKtp] = useState("");
  const [noRek, setNorek] = useState("");
  const [bank, setBank] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("L");
  const [sales, setSales] = useState("");
  const [tglKelahiran, setTglKelahiran] = useState(1);
  const [bulanKelahiran, setBulanKelahiran] = useState(1);
  const [tahunKelahiran, setTahunKelahiran] = useState(1945);
  const [gajiBersih, setGajiBersih] = useState(0);
  const [tenorPengajuan, setTenorPengajuan] = useState(10);
  const [potAngsuranMonthAmount, setPotAngsuranMonthAmount] = useState(0);
  const [noTelp, setNoTelp] = useState("");
  const [maksPlafon, setMaksPlafon] = useState(0);
  const [plafonPengajuan, setPlafonPengajuan] = useState(0);
  const [angkaPelunasan, setAngkaPelunasan] = useState(0);
  const [terimaBersih, setTerimaBersih] = useState(0);
  const [tglUsiaSaatLunas, setTglUsiaSaatLunas] = useState(0);
  const [bulanUsiaSaatLunas, setBulanUsiaSaatLunas] = useState(0);
  const [tahunUsiaSaatLunas, setTahunUsiaSaatLunas] = useState(0);
  const [analisaPerhitunganTglLunas, setAnalisaPerhitunganTglLunas] = useState("");
  const [statusAnalisaPerhitungan, setStatusAnalisaPerhitungan] = useState("OVER");
  const [ratioAngsuranPerBulan, setRatioAngsuranPerBulan] = useState(0);
  const [angsuranPerBulan, setAngsuranPerBulan] = useState(0);
  const [ratioSisaGajiPerBulan, setRatioSisaGajiPerBulan] = useState(0);
  const [sisaGajiPerBulan, setSisaGajiPerBulan] = useState(0);
  const [currentAge, setCurrentAge] = useState({});
  const [tglLahir, setTglLahir] = useState(null);
  const [biayaTitipanStatusPembiayaanLabel, setBiayaTitipanStatusPembiayaanLabel] = useState("");
  const [biayaFlagging, setBiayaFlagging] = useState(0);
  const [checkboxBprsMci, setCheckboxBprsMci] = useState(false);
  const [plafondTenorMelanjutkan, setPlafondTenorMelanjutkan] = useState(false);
  const [arrLooperTenorPengajuan, setArrLooperTenorPengajuan] = useState([]);
  const [checkedCheckboxBiayaTitipanSksupTabMaterial, setCheckedCheckboxBiayaTitipanSksupTabMaterial] = useState(false);

  /**
   * Tabel Asuransi
   *
   */
  useEffect(() => {
    axios
      .get(
        APP_CONFIG.API_URL +
          `tarif-asuransi?status=${selectedJenisProduk.toLowerCase()}&jumlahTenor=${tenorPengajuan}&usia=${currentAge.years}&bulan=${currentAge.months}`
      )
      .then((res) => {
        if (res.data.data) {
          setTabelAsuransi(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedJenisProduk, currentAge, tenorPengajuan]);

  /**
   * Biaya & Titipan
   *
   */
  const [biayaTitipanJasaFrontingAgent, setBiayaTitipanJasaFrontingAgent] = useState(0);
  const [biayaTitipanAdmin, setBiayaTitipanAdmin] = useState(0);
  const [biayaTitipanProvisi, setBiayaTitipanProvisi] = useState(0);
  const [biayaTitipanAsuransi, setBiayaTitipanAsuransi] = useState(0);
  const [biayaTitipanSksupTabMaterial, setBiayaTitipanSksupTabMaterial] = useState(2500000);
  const [biayaTitipanPotAngsuran, setBiayaTitipanPotAngsuran] = useState(0);

  /**
   * M
   *
   */
  const [cellM8, setCellM8] = useState("");
  const [cellM9, setCellM9] = useState("");
  const [cellM13, setCellM13] = useState("");
  const [cellM14, setCellM14] = useState("");

  /**
   * O
   *
   */
  const [cellO24, setCellO24] = useState("");
  const [cellO22, setCellO22] = useState("");
  const [cellO19, setCellO19] = useState("");
  const [cellO18, setCellO18] = useState("");
  const [cellO17, setCellO17] = useState("");

  /**
   * Q
   *
   */
  const [cellQ24, setCellQ24] = useState("");
  const [cellQ23, setCellQ23] = useState("");
  const [cellQ22, setCellQ22] = useState("");
  const [cellQ20, setCellQ20] = useState("");
  const [cellQ19, setCellQ19] = useState("");
  const [cellQ15, setCellQ15] = useState("");
  const [cellQ14, setCellQ14] = useState("");
  const [cellQ13, setCellQ13] = useState("");
  const [cellQ12, setCellQ12] = useState("");
  const [cellQ10, setCellQ10] = useState("");
  const [cellQ9, setCellQ9] = useState("");
  const [cellQ8, setCellQ8] = useState("");
  const [cellQ7, setCellQ7] = useState("");
  const [cellQ6, setCellQ6] = useState("");
  const [cellQ4, setCellQ4] = useState("");
  const [cellQ3, setCellQ3] = useState(80);
  const [cellQ2, setCellQ2] = useState(17);

  /**
   * A
   *
   */
  const [cellA11, setCellA11] = useState(0);

  /**
   * B
   *
   */
  const [cellB11, setCellB11] = useState(0);

  /**
   * C
   *
   */
  const [cellC11, setCellC11] = useState(0);

  useEffect(() => {
    if (checkedCheckboxBiayaTitipanSksupTabMaterial) {
      setBiayaTitipanAsuransi(parseInt(biayaTitipanAsuransi) + parseInt(biayaTitipanSksupTabMaterial));
    } else {
      setBiayaTitipanAsuransi(parseInt(biayaTitipanAsuransi) - parseInt(biayaTitipanSksupTabMaterial));
    }
  }, [checkedCheckboxBiayaTitipanSksupTabMaterial]);

  /**
   * Setting biaya flagging
   *
   */
  useEffect(() => {
    if (selectedJenisPensiun.name == "ASABRI") {
      setBiayaFlagging(300000);
    } else {
      setBiayaFlagging(60000 * cellC11);
    }
  }, [selectedJenisPensiun, cellC11]);

  /**
   * Setting A11
   *
   */
  useEffect(() => {
    setCellA11(tenorPengajuan);
  }, [tenorPengajuan]);

  /**
   * Setting B11
   *
   */
  useEffect(() => {
    setCellB11(cellA11 / 12);
  }, [cellA11]);

  /**
   * Setting C11
   *
   */
  useEffect(() => {
    setCellC11(formulajs.ROUNDUP(cellB11, 0));
  }, [cellB11]);

  /**
   * Tanggal Usia Lunas
   *
   */
  useEffect(() => {
    if (typeof analisaPerhitunganTglLunas == "object") {
      try {
        setTahunUsiaSaatLunas(formulajs.DATEDIF(tglLahir, analisaPerhitunganTglLunas, "Y"));
        setBulanUsiaSaatLunas(formulajs.DATEDIF(tglLahir, analisaPerhitunganTglLunas, "YM"));
        setTglUsiaSaatLunas(formulajs.DATEDIF(tglLahir, analisaPerhitunganTglLunas, "MD"));
      } catch (error) {}
    }
  }, [analisaPerhitunganTglLunas, tahunKelahiran, bulanKelahiran, tglKelahiran]);

  /**
   * Biaya Titipan Status Pembiayaan Label
   *
   */
  useEffect(() => {
    if (selectedStatusPembiayaan == "Mutasi") {
      setBiayaTitipanStatusPembiayaanLabel("Tabungan + Meterai + Mutasi");
    } else if (selectedStatusPembiayaan == "Mutasi & Take Over") {
      setBiayaTitipanStatusPembiayaanLabel("Tabungan + Meterai + Mutasi");
    } else {
      setBiayaTitipanStatusPembiayaanLabel("SKSUP + Tabungan + Meterai");
    }
  }, [selectedStatusPembiayaan]);

  /**
   * Ratio Angsuran Per Bulan
   *
   */
  useEffect(() => {
    setRatioAngsuranPerBulan(((angsuranPerBulan / gajiBersih).toFixed(4) * 100).toFixed(1));
  }, [angsuranPerBulan, gajiBersih]);

  /**
   * Ratio Sisa Gaji Per Bulan
   *
   */
  useEffect(() => {
    if (sisaGajiPerBulan == "DIBAWAH 100 RB") {
      setRatioSisaGajiPerBulan(0);
    } else {
      setRatioSisaGajiPerBulan((sisaGajiPerBulan / gajiBersih) * 100);
    }
  }, [sisaGajiPerBulan, gajiBersih]);

  useEffect(() => {
    loadKprkPos();
    loadStatusPembiayaan();
    loadJenisPensiun();
    loadBpr();
    loadJenisProduk();
    loadFronting();
  }, []);

  /**
   * Load BPR
   *
   */
  const loadBpr = () => {
    axios
      .get(`${APP_CONFIG.API_URL}` + "vendor-pendana")
      .then((res) => {
        setArrBpr(res.data.data);
        setSelectedBPR(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Load Fronting
   *
   */
  const loadFronting = () => {
    axios
      .get(`${APP_CONFIG.API_URL}` + "fronting-agent")
      .then((res) => {
        setArrFrontingAgent(res.data.data);
        setSelectedFrontingAgent(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Load Jenis Produk
   *
   */
  const loadJenisProduk = () => {
    setArrJenisarrJenisProduk(jenisProdukJson);
    setSelectedJenisProduk(jenisProdukJson[0]);
  };

  /**
   * Setting Biaya Titipan Admin
   *
   */
  useEffect(() => {
    setBiayaTitipanAdmin((plafonPengajuan * selectedBPR.admin_fee) / 100);
  }, [selectedBPR, plafonPengajuan]);

  /**
   * Setting Biaya Titipan JasaFrontingAgent
   *
   */
  useEffect(() => {
    setBiayaTitipanJasaFrontingAgent((plafonPengajuan * selectedFrontingAgent.jasa_fronting_agent) / 100);
  }, [selectedFrontingAgent, plafonPengajuan, plafondTenorMelanjutkan]);

  /**
   * Setting Biaya Titipan Provisi
   *
   */
  useEffect(() => {
    setBiayaTitipanProvisi((plafonPengajuan * selectedBPR.provisi_fee) / 100);
  }, [selectedBPR, plafonPengajuan]);

  /**
   * Setting Biaya Titipan Asuransi
   *
   */
  useEffect(() => {
    if (checkboxBprsMci && selectedBPR.code == "01101" && plafondTenorMelanjutkan) {
      setBiayaTitipanAsuransi(
        parseInt((plafonPengajuan * (parseFloat(selectedFrontingAgent.jasa_fronting_agent) + (parseFloat(tabelAsuransi.tarif_asuransi) * 50) / 100)) / 100) +
          parseInt(checkedCheckboxBiayaTitipanSksupTabMaterial ? biayaTitipanSksupTabMaterial : 0)
      );

      if (!checkedCheckboxBiayaTitipanSksupTabMaterial) {
        setTabelAsuransiTarifAsuransi(parseFloat(selectedFrontingAgent.jasa_fronting_agent) + (parseFloat(tabelAsuransi.tarif_asuransi) * 50) / 100);
      }
    } else if (checkboxBprsMci && selectedBPR.code == "01101") {
      setBiayaTitipanAsuransi(
        parseInt((plafonPengajuan * (parseFloat(selectedFrontingAgent.jasa_fronting_agent) + (parseFloat(tabelAsuransi.tarif_asuransi) * 75) / 100)) / 100) +
          parseInt(checkedCheckboxBiayaTitipanSksupTabMaterial ? biayaTitipanSksupTabMaterial : 0)
      );

      if (!checkedCheckboxBiayaTitipanSksupTabMaterial) {
        setTabelAsuransiTarifAsuransi(parseFloat(selectedFrontingAgent.jasa_fronting_agent) + (parseFloat(tabelAsuransi.tarif_asuransi) * 75) / 100);
      }
    } else {
      setBiayaTitipanAsuransi(
        parseInt((plafonPengajuan * (parseFloat(selectedFrontingAgent.jasa_fronting_agent) + parseFloat(tabelAsuransi.tarif_asuransi))) / 100) +
          parseInt(checkedCheckboxBiayaTitipanSksupTabMaterial ? biayaTitipanSksupTabMaterial : 0)
      );

      if (!checkedCheckboxBiayaTitipanSksupTabMaterial) {
        setTabelAsuransiTarifAsuransi(parseFloat(selectedFrontingAgent.jasa_fronting_agent) + parseFloat(tabelAsuransi.tarif_asuransi));
      }
    }
  }, [
    selectedBPR,
    plafonPengajuan,
    checkboxBprsMci,
    checkedCheckboxBiayaTitipanSksupTabMaterial,
    plafondTenorMelanjutkan,
    selectedFrontingAgent,
    tabelAsuransi,
  ]);

  /**
   * Sisa Gaji Per Bulan
   *
   */
  useEffect(() => {
    setSisaGajiPerBulan(gajiBersih - angsuranPerBulan);
  }, [gajiBersih, angsuranPerBulan]);

  /**
   * Setting Biaya Titipan PotAngsuran
   *
   */
  useEffect(() => {
    setBiayaTitipanPotAngsuran(angsuranPerBulan * potAngsuranMonthAmount);
  }, [angsuranPerBulan, potAngsuranMonthAmount]);

  /**
   * Cell Q4
   *
   */
  useEffect(() => {
    setCellQ4(cellQ3 - currentAge.years);
  }, [cellQ3, currentAge]);

  /**
   * Cell Q6
   *
   */
  useEffect(() => {
    if (selectedBPR.code == "01101") {
      setCellQ6(180);
    } else {
      setCellQ6(120);
    }
  }, [selectedBPR]);

  /**
   * Setting Q7
   *
   */
  useEffect(() => {
    if (currentAge.years < cellQ2) {
      setCellQ7(0);
    } else if (currentAge.years < cellQ3) {
      setCellQ7(cellQ4 * 12 - (currentAge.months + 1));
    } else if (currentAge.years >= cellQ3) {
      setCellQ7(0);
    }
  }, [currentAge, cellQ2, cellQ3, cellQ4]);

  /**
   * Setting Q8
   *
   */
  useEffect(() => {
    setCellQ8(currentAge.years + currentAge.months / 12);
  }, [currentAge]);

  /**
   * Setting Q9
   *
   */
  useEffect(() => {
    if (cellQ7 <= cellQ6) {
      setCellQ9(cellQ7);
    } else {
      setCellQ9(cellQ6);
    }
  }, [cellQ6, cellQ7]);

  /**
   * Setting Q10
   *
   */
  useEffect(() => {
    if (cellQ8 < 80) {
      setCellQ10(cellQ9);
    } else {
      setCellQ10(0);
    }
  }, [cellQ8, cellQ9]);

  /**
   * Setting Q12
   *
   */
  useEffect(() => {
    if (selectedBPR.code == "01101") {
      setCellQ12(200000000);
    } else {
      setCellQ12(150000000);
    }
  }, [selectedBPR]);

  /**
   * Setting Q13
   *
   */
  useEffect(() => {
    if (cellQ8 < cellQ2) {
      setCellQ13(0);
    } else if (cellQ8 < cellQ3) {
      setCellQ13(cellQ12);
    } else if (cellQ8 >= cellQ3) {
      setCellQ13(0);
    }
  }, [cellQ8, cellQ2, cellQ3]);

  /**
   * Setting Q14
   *
   */
  useEffect(() => {
    setCellQ14((gajiBersih * 95) / 100);
  }, [gajiBersih]);

  /**
   * Setting Q15
   *
   */
  useEffect(() => {
    setCellQ15((cellQ14 * 94) / 100);
  }, [cellQ14]);

  /**
   * Setting Q19
   *
   */
  useEffect(() => {
    setCellQ19(parseInt(formulajs.PV(cellO19 / 12, tenorPengajuan, cellQ14) * -1));
  }, [cellO19, tenorPengajuan, cellQ14]);

  /**
   * Setting Q20
   *
   */
  useEffect(() => {
    if (cellQ19 <= cellQ13) {
      setCellQ20(formulajs.FLOOR(cellQ19, 100000));
    } else {
      setCellQ20(formulajs.FLOOR(cellQ13, 100000));
    }
  }, [cellQ19, cellQ13]);

  /**
   * Setting Q22
   *
   */
  useEffect(() => {
    setCellQ22(FuncUtil.calculatePMT(cellO19 / 12, tenorPengajuan, plafonPengajuan) * -1 * 1.06);
  }, [cellO19, tenorPengajuan, plafonPengajuan]);

  /**
   * Setting Q23
   *
   */
  useEffect(() => {
    setCellQ23((cellQ22 * 6) / 100);
  }, [cellQ22]);

  /**
   * Setting Q24
   *
   */
  useEffect(() => {
    setCellQ24(cellQ22 - cellQ23);
  }, [cellQ22, cellQ23]);

  /**
   * Setting O17
   *
   */
  useEffect(() => {
    setCellO17(false);
  }, []);

  /**
   * Setting O18
   *
   */
  useEffect(() => {
    setCellO18(false);
  }, []);

  /**
   * Setting O19
   *
   */
  useEffect(() => {
    setCellO19(formulajs.RATE(tenorPengajuan, 1 * (selectedBPR.margin / 100) + 1 / tenorPengajuan, -1, 0, 0, 0.1) * 12);
  }, [tenorPengajuan, selectedBPR]);

  /**
   * Setting O22
   *
   */
  useEffect(() => {
    setCellO22((cellQ22 * tenorPengajuan - plafonPengajuan) / tenorPengajuan / plafonPengajuan);
  }, [cellQ22, tenorPengajuan, plafonPengajuan]);

  /**
   * Setting O24
   *
   */
  useEffect(() => {
    setCellO24((cellQ24 * tenorPengajuan - plafonPengajuan) / tenorPengajuan / plafonPengajuan);
  }, [cellQ24, tenorPengajuan, plafonPengajuan]);

  /**
   * Setting M8
   *
   */
  useEffect(() => {
    if (tenorPengajuan > cellQ10) {
      setCellM8("OVER");
    } else {
      setCellM8("OK");
    }
  }, [tenorPengajuan, cellQ10]);

  /**
   * Setting M9
   *
   */
  useEffect(() => {
    if (plafonPengajuan > maksPlafon) {
      setCellM9("OVER");
    } else {
      setCellM9("OK");
    }
  }, [plafonPengajuan, maksPlafon]);

  useEffect(() => {
    try {
      setAnalisaPerhitunganTglLunas(formulajs.EDATE(DateUtil.getNowDateFormatted(), tenorPengajuan));
    } catch (error) {}
  }, [tenorPengajuan]);

  /**
   * Setting M13
   *
   */
  useEffect(() => {
    if (selectedBPR.code == "01101") {
      setCellM13(200000000);
    } else {
      setCellM13(150000000);
    }
  }, [selectedBPR]);

  /**
   * Setting M14
   *
   */
  useEffect(() => {
    if (selectedBPR.code == "01101") {
      setCellM14(180);
    } else {
      setCellM14(120);
    }
  }, [selectedBPR]);

  /**
   * Tanggal Lahir
   *
   */
  useEffect(() => {
    try {
      setTglLahir(new Date(`${tahunKelahiran}-${bulanKelahiran}-${tglKelahiran}`));
    } catch (error) {}
  }, [tahunKelahiran, bulanKelahiran, tglKelahiran]);

  /**
   * Status Analisa Perhitungan
   *
   */
  useEffect(() => {
    if (cellM8 == "OK" && cellM9 == "OK") {
      setStatusAnalisaPerhitungan("OK");
    } else {
      setStatusAnalisaPerhitungan("OVER");
    }
  }, [cellM8, cellM9]);

  /**
   * Current Age State
   *
   */
  useEffect(() => {
    if (tglLahir) {
      try {
        setCurrentAge({
          years: formulajs.DATEDIF(tglLahir, new Date(), "y"),
          months: formulajs.DATEDIF(tglLahir, new Date(), "ym"),
          days: formulajs.DATEDIF(tglLahir, new Date(), "md"),
        });
      } catch (error) {}
    }
  }, [tglLahir]);

  /**
   * Setting Gaji Per Bulan
   *
   */
  useEffect(() => {
    setSisaGajiPerBulan(gajiBersih - angsuranPerBulan);
  }, [angsuranPerBulan, gajiBersih]);

  /**
   * Setting Angsuran Per Bulan
   *
   */
  useEffect(() => {
    setAngsuranPerBulan(cellQ22);
  }, [cellQ22]);

  /**
   * Setting Maks Plafon
   *
   */
  useEffect(() => {
    setMaksPlafon(cellQ20);
  }, [cellQ20]);

  /**
   * Terima Bersih
   *
   */
  useEffect(() => {
    if (selectedBPR.code != "01101") {
      setTerimaBersih(
        plafonPengajuan -
          formulajs.SUM(
            biayaTitipanAdmin,
            biayaTitipanProvisi,
            biayaTitipanAsuransi,
            checkedCheckboxBiayaTitipanSksupTabMaterial ? 0 : biayaTitipanSksupTabMaterial,
            biayaTitipanPotAngsuran,
            biayaTitipanJasaFrontingAgent
          ) -
          angkaPelunasan -
          biayaFlagging
      );
    } else {
      setTerimaBersih(
        plafonPengajuan -
          formulajs.SUM(
            biayaTitipanAdmin,
            biayaTitipanProvisi,
            biayaTitipanAsuransi,
            checkedCheckboxBiayaTitipanSksupTabMaterial ? 0 : biayaTitipanSksupTabMaterial,
            biayaTitipanPotAngsuran
          ) -
          angkaPelunasan -
          biayaFlagging
      );
    }

    if (checkedCheckboxBiayaTitipanSksupTabMaterial) {
      setTabelAsuransiTarifAsuransi((biayaTitipanAsuransi / plafonPengajuan) * 100);
    } else {
      if (checkboxBprsMci && selectedBPR.code == "01101" && plafondTenorMelanjutkan) {
        setTabelAsuransiTarifAsuransi(parseFloat(selectedFrontingAgent.jasa_fronting_agent) + (parseFloat(tabelAsuransi.tarif_asuransi) * 50) / 100);
      } else if (checkboxBprsMci && selectedBPR.code == "01101") {
        setTabelAsuransiTarifAsuransi(parseFloat(selectedFrontingAgent.jasa_fronting_agent) + (parseFloat(tabelAsuransi.tarif_asuransi) * 75) / 100);
      } else {
        setTabelAsuransiTarifAsuransi(parseFloat(selectedFrontingAgent.jasa_fronting_agent) + parseFloat(tabelAsuransi.tarif_asuransi));
      }
    }
  }, [
    selectedFrontingAgent,
    tabelAsuransi,
    checkboxBprsMci,
    plafondTenorMelanjutkan,
    checkedCheckboxBiayaTitipanSksupTabMaterial,
    plafonPengajuan,
    biayaTitipanAdmin,
    biayaTitipanProvisi,
    biayaTitipanAsuransi,
    biayaTitipanSksupTabMaterial,
    biayaTitipanPotAngsuran,
    angkaPelunasan,
    selectedJenisPensiun,
    selectedBPR,
  ]);

  const loadKprkPos = () => {
    setKprkPosArr(kprkPosJson);
  };

  const loadStatusPembiayaan = () => {
    setStatusPembiayaanArr(statusPembiayaanJson);
  };

  const loadJenisPensiun = () => {
    setJenisPensiunArr(jenisPensiunJson);
    setSelectedJenisPensiun(jenisPensiunJson[0]);
  };

  useEffect(() => {
    let arrItems = [];

    if (tenorPengajuan) {
      for (let i = 0; i < tenorPengajuan; i++) {
        arrItems.push({
          tanggal: DateUtil.formatDateObjToDMY(DateUtil.getDateObjAddMonthFromNow(i)),
          angsuran: angsuranPerBulan,
        });
      }
    }

    arrItems.map((e, i) => {
      e.pokok_margin = Math.ceil(e.angsuran / 1.06);
      e.fee_pos = Math.ceil(e.pokok_margin * 0.06);
      e.outstanding = arrItems.reduce((accumulator, currObj) => accumulator + e.angsuran, 0) - e.angsuran * (i + 1);

      return e;
    });

    setArrLooperTenorPengajuan(arrItems);
  }, [tenorPengajuan, angsuranPerBulan]);

  return (
    <div className="App">
      <div className="px-lg-5 py-4">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="simulasi-tab"
              data-bs-toggle="tab"
              data-bs-target="#simulasi"
              type="button"
              role="tab"
              aria-controls="simulasi"
              aria-selected="true"
            >
              Simulasi
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="tabel-angsuran-tab"
              data-bs-toggle="tab"
              data-bs-target="#tabel-angsuran"
              type="button"
              role="tab"
              aria-controls="tabel-angsuran"
              aria-selected="false"
            >
              Tabel Angsuran
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="simulasi" role="tabpanel" aria-labelledby="simulasi-tab">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-2">
                  <div className="card-header">
                    <h6>ISI DATA DENGAN LENGKAP</h6>
                    <p className="">PASTIKAN PENGISIAN SESUAI DATA PENGAJUAN</p>
                  </div>
                  <div className="card-body">
                    <table className="table table-sm">
                      <tr>
                        <th style={{ textAlign: "right" }}>FRONTING</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="fronting"
                            id="fronting"
                            className="form-control form-control-sm"
                            value={selectedFrontingAgent.id}
                            onChange={(e) => {
                              setSelectedFrontingAgent(arrFrontingAgent[e.target.selectedIndex]);
                            }}
                          >
                            {arrFrontingAgent.map((frontingAgentItem) => {
                              return <option value={frontingAgentItem.id}>{frontingAgentItem.nama}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Jenis Produk</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="jenis_produk"
                            id="jenis_produk"
                            className="form-control form-control-sm"
                            value={selectedJenisProduk}
                            onChange={(e) => {
                              setSelectedJenisProduk(arrJenisProduk[e.target.selectedIndex]);
                            }}
                          >
                            {arrJenisProduk.map((jenisProdukItem) => {
                              return <option value={jenisProdukItem}>{jenisProdukItem}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Sumber Dana</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="sumber_dana"
                            id="sumber_dana"
                            className="form-control form-control-sm"
                            value={selectedBPR.name}
                            onChange={(e) => {
                              setSelectedBPR(arrBpr[e.target.selectedIndex]);
                            }}
                          >
                            {arrBpr.map((bprItem) => {
                              return <option value={bprItem.name}>{bprItem.name}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Layanan KPRK POS</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="layanan_kprk_pos"
                            id="layanan_kprk_pos"
                            className="form-control form-control-sm"
                            value={selectedKprkPosArrArr.name}
                            onChange={(e) => {
                              setSelectedKprkPosArr(kprkPosArr[e.target.selectedIndex]);
                            }}
                          >
                            {kprkPosArr.map((kprkPosItem) => {
                              return <option value={kprkPosArr.name}>{kprkPosItem.name}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Jumlah KCP</th>
                        <td className=" px-3 pb-3">:</td>
                        <th className="text-left">
                          {kprkPosArr.length > 0 && Object.keys(selectedKprkPosArrArr).length == 0
                            ? kprkPosArr[0].amount_kantor_cabang
                            : selectedKprkPosArrArr.amount_kantor_cabang}{" "}
                          Kantor Pos Cabang
                        </th>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Nama Debitur (KTP)</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <input
                            type="text"
                            name="nama_debitur_ktp"
                            id="nama_debitur_ktp"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={namaDebitur}
                            onChange={(e) => {
                              setNamaDebitur(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>NOPEN</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <input
                            type="text"
                            name="nopen"
                            id="nopen"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={nopen}
                            onChange={(e) => {
                              setNopen(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Status Pembiayaan</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="status_pembiayaan"
                            id="status_pembiayaan"
                            className="form-control form-control-sm"
                            value={selectedStatusPembiayaan}
                            onChange={(e) => {
                              setSelectedStatusPembiayaan(statusPembiayaanArr[e.target.selectedIndex]);
                            }}
                          >
                            {statusPembiayaanArr.map((statusPembiayaanItem) => {
                              return <option value={statusPembiayaanItem}>{statusPembiayaanItem}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Pelunasan Ke</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <input
                            type="number"
                            name="pelunasan_ke"
                            id="pelunasan_ke"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={pelunasanKe}
                            onChange={(e) => {
                              setPelunasanKe(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Asal Kantor Bayar</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <input
                            type="text"
                            name="asal_kantor_bayar"
                            id="asal_kantor_bayar"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={asalKantorBayar}
                            onChange={(e) => {
                              setAsalKantorBayar(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Jenis Pensiun</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="jenis_pensiun"
                            id="jenis_pensiun"
                            className="form-control form-control-sm"
                            value={selectedJenisPensiun.name}
                            onChange={(e) => {
                              setSelectedJenisPensiun(jenisPensiunArr[e.target.selectedIndex]);
                            }}
                          >
                            {jenisPensiunArr.map((jenisPensiunItem) => {
                              return <option value={jenisPensiunItem.name}>{jenisPensiunItem.name}</option>;
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Tujuan Kantor Bayar</th>
                        <td className=" px-3 pb-3">:</td>
                        <th style={{ fontWeight: "bold", textAlign: "left" }}>POS</th>
                      </tr>
                      {selectedBPR.code == "01101" ? (
                        <tr>
                          <th style={{ textAlign: "right" }}>Bank Kreditur Sebelumnya</th>
                          <td className=" px-3 pb-3">:</td>
                          <th style={{ fontWeight: "bold", textAlign: "left" }}>
                            <div>
                              <input
                                type="checkbox"
                                id="bprs_mci"
                                checked={checkboxBprsMci}
                                onChange={(e) => {
                                  setCheckboxBprsMci(e.target.checked);
                                }}
                              />
                              <label for="bprs_mci">BPR / BPRS yang sama</label>
                            </div>
                            {checkboxBprsMci ? (
                              <div>
                                <input
                                  type="checkbox"
                                  id="plafond_tenor_melanjutkan"
                                  checked={plafondTenorMelanjutkan}
                                  onChange={(e) => {
                                    setPlafondTenorMelanjutkan(e.target.checked);
                                  }}
                                />
                                <label for="plafond_tenor_melanjutkan">Plafond sama, Tenor melanjutkan</label>
                              </div>
                            ) : (
                              <></>
                            )}
                          </th>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </table>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h6>Calculated by BGC</h6>
                  </div>
                  <div className="card-body">
                    <table className="table table-sm">
                      <tr>
                        <th style={{ textAlign: "right" }}>No KTP</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <input
                            type="number"
                            name="no_ktp"
                            id="no_ktp"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={noKtp}
                            onChange={(e) => {
                              setNoKtp(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>No Rek</th>
                        <td className=" px-3 pb-3">:</td>
                        <td>
                          <input
                            type="number"
                            name="no_rek"
                            id="no_rek"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={noRek}
                            onChange={(e) => {
                              setNorek(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Bank</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <input
                            type="text"
                            name="bank"
                            id="bank"
                            className="form-control form-control-sm"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                            value={bank}
                            onChange={(e) => {
                              setBank(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "right" }}>Jenis Kelamin</th>
                        <td className=" px-3 pb-3">:</td>
                        <td className="">
                          <select
                            name="jenis_kelamin"
                            id="jenis_kelamin"
                            className="form-control form-control-sm"
                            value={jenisKelamin}
                            onChange={(e) => {
                              setJenisKelamin(e.target.value);
                            }}
                          >
                            <option value="L">L</option>
                            <option value="P">P</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "right" }}>No TLP</td>
                        <td className=" px-3 pb-3">:</td>
                        <td>
                          <input
                            type="number"
                            name="no_telp"
                            id="no_telp"
                            style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold", textAlign: "left", border: "1px solid #FFF" }}
                            className="form-control form-control-sm"
                            value={noTelp}
                            onChange={(e) => {
                              setNoTelp(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-header">
                    <h6>ANALISA PERHITUNGAN</h6>
                    <p className="">BPR KOTA MADIUN PLATINUM</p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4">
                        <table className="table table-sm">
                          <tr>
                            <td className="pb-2">Sales</td>
                            <td className="px-3 pb-2">:</td>
                            <td className="pb-2">
                              <input
                                type="text"
                                name="sales"
                                id="sales"
                                className="form-control form-control-sm"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                                value={sales}
                                onChange={(e) => {
                                  setSales(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              KELAHIRAN
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>TGL</td>
                            <td style={{ fontWeight: "bold" }}>Bulan</td>
                            <td style={{ fontWeight: "bold" }}>Tahun</td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="tgl_lahir"
                                id="tgl_lahir"
                                className="form-control text-center  form-control-sm"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                                value={tglKelahiran}
                                onChange={(e) => {
                                  setTglKelahiran(e.target.value);
                                }}
                              />
                            </td>
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="bulan_lahir"
                                id="bulan_lahir"
                                className="form-control text-center  form-control-sm"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                                value={bulanKelahiran}
                                onChange={(e) => {
                                  setBulanKelahiran(e.target.value);
                                }}
                              />
                            </td>
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="tahun_lahir"
                                id="tahun_lahir"
                                className="form-control text-center  form-control-sm"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold" }}
                                value={tahunKelahiran}
                                onChange={(e) => {
                                  setTahunKelahiran(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <th className="" colSpan={2}>
                              Gaji Bersih
                            </th>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <th>Rp</th>
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="gaji_bersih"
                                id="gaji_bersih"
                                className="form-control form-control-sm"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold", textAlign: "right" }}
                                value={FuncUtil.formatRupiah(gajiBersih)}
                                onChange={(e) => {
                                  setGajiBersih(e.target.value.replaceAll(".", ""));
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <th style={{ width: "65%", textAlign: "center" }} className="px-4">
                              Maksimal Tenor
                            </th>
                            <td className="bg-white px-4" style={{ fontWeight: "bold" }}>
                              {cellQ10} Bulan
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold", textAlign: "center" }} className="px-4">
                              Tenor Pengajuan
                            </td>
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="tenor_pengajuan"
                                id="tenor_pengajuan"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold", textAlign: "center", border: "1px solid #FFF" }}
                                className="form-control form-control-sm"
                                value={FuncUtil.formatRupiah(tenorPengajuan)}
                                onChange={(e) => {
                                  setTenorPengajuan(e.target.value.replaceAll(".", ""));
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              BIAYA & TITIPAN
                            </td>
                          </tr>
                          {selectedBPR.code != "01101" ? (
                            <tr className="bg-[#F7F7F7]">
                              <td style={{ fontWeight: "bold" }} className="">
                                Jasa Fronting Agent
                              </td>
                              <td style={{ fontWeight: "bold", width: "25%" }}>{selectedFrontingAgent.jasa_fronting_agent}%</td>
                              <td style={{ fontWeight: "bold" }} className="bg-white">
                                {FuncUtil.formatRupiah(biayaTitipanJasaFrontingAgent)}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} className="">
                              Admin Bank
                            </td>
                            <td style={{ fontWeight: "bold", width: "25%" }}>{selectedBPR.admin_fee}%</td>
                            <td style={{ fontWeight: "bold" }} className="bg-white">
                              {FuncUtil.formatRupiah(biayaTitipanAdmin)}
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} className="">
                              Provisi Bank
                            </td>
                            <td style={{ fontWeight: "bold" }} className="">
                              {selectedBPR.provisi_fee}%
                            </td>
                            <td style={{ fontWeight: "bold" }} className="bg-white">
                              {FuncUtil.formatRupiah(biayaTitipanProvisi)}
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} className="">
                              Asuransi
                            </td>
                            <td style={{ fontWeight: "bold" }} className="">
                              {tabelAsuransiTarifAsuransi.toFixed(3)}%
                            </td>
                            <td className="bg-white">{FuncUtil.formatRupiah(parseInt(Math.round(biayaTitipanAsuransi)))}</td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={2}>
                              {biayaTitipanStatusPembiayaanLabel}
                            </td>
                            <td className="">
                              <div className="flex items-center gap-x-1">
                                <input
                                  type="text"
                                  name="biaya_titipan_sksup_tab_material"
                                  id="biaya_titipan_sksup_tab_material"
                                  style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold", textAlign: "center", border: "1px solid #FFF" }}
                                  className="form-control form-control-sm bg-[#366092]"
                                  value={checkedCheckboxBiayaTitipanSksupTabMaterial ? 0 : FuncUtil.formatRupiah(biayaTitipanSksupTabMaterial)}
                                  onChange={(e) => {
                                    setBiayaTitipanSksupTabMaterial(e.target.value.replaceAll(".", ""));
                                  }}
                                />

                                <input
                                  type="checkbox"
                                  checked={checkedCheckboxBiayaTitipanSksupTabMaterial}
                                  onChange={() => {
                                    setCheckedCheckboxBiayaTitipanSksupTabMaterial(!checkedCheckboxBiayaTitipanSksupTabMaterial);
                                  }}
                                  id="checkboxBiayaTitipanSksupTabMaterial"
                                />
                                <label for="checkboxBiayaTitipanSksupTabMaterial"></label>
                              </div>
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Pot Angsuran</td>
                            <td className="">
                              <select
                                name="pot_angsuran"
                                id="pot_angsuran"
                                className="form-control form-control-sm"
                                value={potAngsuranMonthAmount}
                                onChange={(e) => {
                                  setPotAngsuranMonthAmount(e.target.value);
                                }}
                              >
                                <LooperOptions times={tenorPengajuan} />
                              </select>
                            </td>
                            <td className="bg-white">{FuncUtil.formatRupiah(Math.round(biayaTitipanPotAngsuran))}</td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <th colSpan={2} style={{ fontWeight: "bold", textAlign: "left" }} className="px-2">
                              Biaya Flagging
                            </th>
                            <td className="bg-white" style={{ textAlign: "center" }}>
                              Rp{FuncUtil.formatRupiah(biayaFlagging)}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-4">
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              Usia Saat Ini
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Tahun</td>
                            <td style={{ fontWeight: "bold" }}>Bulan</td>
                            <td style={{ fontWeight: "bold" }}>Hari</td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold", background: "#FFF" }}>{currentAge.years}</td>
                            <td style={{ fontWeight: "bold", background: "#FFF" }}>{currentAge.months}</td>
                            <td style={{ fontWeight: "bold", background: "#FFF" }}>{currentAge.days}</td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Margin</td>
                            <td style={{ fontWeight: "bold" }}>Maksimal Plafond</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>{selectedBPR.margin}%</td>
                            <td style={{ fontWeight: "bold" }}>Rp{FuncUtil.formatRupiah(maksPlafon)}</td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={2}>
                              Plafon Pengajuan
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Rp</td>
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="plafon_pengajuan"
                                id="plafon_pengajuan"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold", textAlign: "center", border: "1px solid #FFF" }}
                                className="form-control form-control-sm"
                                value={FuncUtil.formatRupiah(plafonPengajuan)}
                                onChange={(e) => {
                                  setPlafonPengajuan(e.target.value.replaceAll(".", ""));
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={2}>
                              Angka Pelunasan
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Rp</td>
                            <td className="bg-[#366092]">
                              <input
                                type="text"
                                name="angka_pelunasan"
                                id="angka_pelunasan"
                                style={{ backgroundColor: "#366092", color: "#FFF", fontWeight: "bold", textAlign: "center", border: "1px solid #FFF" }}
                                className="form-control form-control-sm"
                                value={FuncUtil.formatRupiah(angkaPelunasan)}
                                onChange={(e) => {
                                  setAngkaPelunasan(e.target.value.replaceAll(".", ""));
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={2}>
                              Terima Bersih
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Rp</td>
                            <td className="bg-[#FFF] text-primary" style={{ fontWeight: "bold" }}>
                              {FuncUtil.formatRupiah(Math.round(terimaBersih))}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-4">
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              Usia Saat Lunas
                            </td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Tahun</td>
                            <td style={{ fontWeight: "bold" }}>Bulan</td>
                            <td style={{ fontWeight: "bold" }}>Hari</td>
                          </tr>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold", background: "#FFF" }}>{tahunUsiaSaatLunas}</td>
                            <td style={{ fontWeight: "bold", background: "#FFF" }}>{bulanUsiaSaatLunas}</td>
                            <td style={{ fontWeight: "bold", background: "#FFF" }}>{tglUsiaSaatLunas}</td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr>
                            <td className="">Lunas</td>
                            <td className="">{DateUtil.formatDateObjToHumanReadable(analisaPerhitunganTglLunas)}</td>
                          </tr>
                          <tr>
                            <td className="">{statusAnalisaPerhitungan}</td>
                            <td className="">Analisa Perhitungan</td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Ratio</td>
                            <td style={{ fontWeight: "bold" }}>Angsuran Per-Bulan</td>
                          </tr>
                          <tr>
                            <td className="">{ratioAngsuranPerBulan}%</td>
                            <td className="">Rp{FuncUtil.formatRupiah(Math.round(angsuranPerBulan))}</td>
                          </tr>
                        </table>
                        <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                          <tr className="bg-[#F7F7F7]">
                            <td style={{ fontWeight: "bold" }}>Ratio</td>
                            <td style={{ fontWeight: "bold" }}>Sisa Gaji Per-Bulan</td>
                          </tr>
                          <tr>
                            <td className="">{ratioSisaGajiPerBulan.toFixed(1)}%</td>
                            <td className="">Rp{FuncUtil.formatRupiah(Math.round(sisaGajiPerBulan))}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tabel-angsuran" role="tabpanel" aria-labelledby="tabel-angsuran-tab">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h6>
                      TABEL <br /> ANGSURAN
                    </h6>
                  </div>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <table className="table table-sm table-bordered" style={{ borderCollapse: "unset" }}>
                      <thead>
                        <tr style={{ background: "#366092", color: "white" }}>
                          <th>No</th>
                          <th>Tanggal</th>
                          <th>Pokok + Margin</th>
                          <th>Fee Pos</th>
                          <th>Angsuran</th>
                          <th>Outstanding</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}></td>
                          <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}></td>
                          <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            Rp{FuncUtil.formatRupiah(arrLooperTenorPengajuan.reduce((acc, curr) => acc + curr.pokok_margin, 0))}
                          </td>
                          <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            Rp{FuncUtil.formatRupiah(arrLooperTenorPengajuan.reduce((acc, curr) => acc + curr.fee_pos, 0))}
                          </td>
                          <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            Rp{FuncUtil.formatRupiah(parseInt(Math.round(arrLooperTenorPengajuan.reduce((acc, curr) => acc + curr.angsuran, 0))))}
                          </td>
                          <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            Rp
                            {arrLooperTenorPengajuan.length > 0
                              ? FuncUtil.formatRupiah(parseInt(Math.round(arrLooperTenorPengajuan[0].outstanding + angsuranPerBulan)))
                              : 0}
                          </td>
                        </tr>
                        {arrLooperTenorPengajuan.map((e, i) => {
                          return (
                            <tr>
                              <td style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}>{i + 1}</td>
                              <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{e.tanggal}</td>
                              <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Rp{FuncUtil.formatRupiah(e.pokok_margin)}</td>
                              <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Rp{FuncUtil.formatRupiah(e.fee_pos)}</td>
                              <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Rp{FuncUtil.formatRupiah(parseInt(Math.round(e.angsuran)))}</td>
                              <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Rp{FuncUtil.formatRupiah(parseInt(Math.round(e.outstanding)))}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
